import React, { useEffect, useRef, useState } from "react";


import LogoMarca from '../../../assets/genius.png';
import FundoTransparent from '../../../assets/fundotransparent.png';
import WhatsAppButton from "./whatsapp";
import WhatsappIcon from '../../../assets/image.png';
import emailjs from 'emailjs-com';


import '../Home/home.css';
import '../Home/home.mobile.css'
import ContactPage from "../../../SiteLanding/contactPage";
import Services from "./modal-services";
import axios from "axios";
import ModalBoasVindas from "./modal-boasvindas";
import ProjectOne from "../../../Modal/projectOne";


function Home() {

  const [showVideo, setShowVideo] = useState(false);
  const [showModalServices, setShowModalServices] = useState(false);
  const [isModalBoasVindas, setIsModalBoasVindas] = useState(false);
  const [projectOne, setProjectOne] = useState(false);
  const [openProject, setOpenProject] = useState(false);
  const timeoutRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail();
  }

  const sendEmail = async (e) => {
    const templateParams = {
      from_name: formData.name,
      email: formData.email,
    };
    try {
      const result = await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      );
      // alert('E-mail enviado com sucesso!');
      setFormData({ name: '', email: '' });

    } catch (error) {
      console.error('Erro ao enviar o e-mail:', error);
      alert(`Erro ao enviar o e-mail: ${error.text || error.message}`);
    }
  };

  useEffect(() => {
    const sections = document.querySelectorAll('section');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
        }
      });
    });

    sections.forEach((section) => {
      observer.observe(section);
    });
    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  return (
    <div className="page-container">
      <div className="content-wrap">
        <div className="template">
          <nav>
            <ul>
              <li><a href="#home">Home</a></li>
              <li><a href="#about">Sobre</a></li>
              <li><a href="#services">Serviços</a></li>
              <li><a href="#contact">Contato</a></li>
              <li><a href="/blog">Blog</a></li>
            </ul>
          </nav>
          <img className="fundo" src={FundoTransparent} alt="" />
          <section className="logo">
            <section className="logoBlack">
              <img src={LogoMarca} alt="Logo Genius In Tech" />
            </section>
          </section>
          <main>
            <section className="_secHome" id="home">
              <h1>Bem-vindo ao Genius In Tech</h1>
              <p>Inovação e tecnologia ao seu alcance.</p>
              <div className="button-container">
                <button className="cta-button" onClick={() => setShowModalServices(true)}>Conheça nossos serviços</button>
                <button className="video-button" onClick={() => setShowVideo(true)}>Assista o vídeo</button>
              </div>
            </section>
            <Services showModalServices={showModalServices} setShowModalServices={setShowModalServices} />
            {showVideo && (
              <div className="video-modal">
                <div className="video-container">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/cxRV8_PkP1s"
                    title="Apresentação Genius In Tech"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <button className="close-video" onClick={() => setShowVideo(false)}>Fechar</button>
                </div>
              </div>
            )}
            <section className="_secSobreNos" id="about">
              <h2>Sobre Nós</h2>
              <p>Somos uma empresa focada em soluções inovadoras no campo da tecnologia e desenvolvimento de software. Nosso objetivo é transformar ideias em produtos digitais eficientes e de alta qualidade.</p>
            </section>
            <section className="_secMissao">
              <h2>Missão</h2>
              <p>Entregar soluções tecnológicas que façam a diferença na vida de nossos clientes.</p>
            </section>
            <section className="_secVisao">
              <h2>Visão</h2>
              <p>Conheça nossa visão.</p>
              <p>Ser referência em inovação e desenvolvimento tecnológico no mercado global.</p>
            </section>
            <section className="_secValores">
              <h2>Valores</h2>
              <ul>
                <li>Inovação</li>
                <li>Qualidade</li>
                <li>Compromisso com o cliente</li>
                <li>Transparência</li>
              </ul>
            </section>
            <section className="_secSevicos" id="services">
              <h2>Serviços</h2>
              <p>Descubra os serviços que oferecemos.</p>
            </section>
            <ModalBoasVindas isModalBoasVindas={isModalBoasVindas} setIsModalBoasVindas={setIsModalBoasVindas} />
            <section className="_secContato" id="contact">
              <h2>Qual o site ideal para seu negócio.</h2>
              <h1>Receba Nossos Serviços.</h1>
              <form onSubmit={handleSubmit}>
                <article>
                  <label htmlFor="name">Nome:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required />
                </article>
                <article>
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required />
                </article>
                <article>
                  <button type="submit" onClick={() => setIsModalBoasVindas(true)} >Enviar</button>
                </article>
              </form>
            </section>
            <ProjectOne ativo={projectOne} setAtivo={setProjectOne} />
            <section className="_secPortfolio" id="portfolio">
              <h2>Portfólio</h2>
              <div className="portfolio-list">
                <div className="portfolio-item" onClick={() => {
                  if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                  }
                  timeoutRef.current = setTimeout(() => {
                    setProjectOne(true);
                  }, 3000);
                }}>
                  <h3>Projeto 1</h3>
                  <p>Descrição do projeto.</p>
                  <p> {projectOne ? 'fechar ⬏' : 'saiba mais ⬎'}</p>
                </div>
              </div>
              <div className="portfolio-item">
                <h3>Projeto 2</h3>
                <p>Descrição do projeto.</p>
                <p>fechar ⬏ : saiba mais ⬎</p>
              </div>
              <div className="portfolio-item">
                <h3>Projeto 3</h3>
                <p>Descrição do projeto.</p>
                <p>fechar ⬏ : saiba mais ⬎</p>
              </div>
            </section>
            <section className="_secTestimonials" id="testimonials">
              <h2>Depoimentos</h2>
              <div className="testimonials-list">
                <div className="testimonial-item">
                  <p> A Genius In Tech transformou a maneira como operamos nosso negócio. A solução deles foi fundamental para nosso sucesso." - Cliente A Kamisaria Zanuto </p>
                </div>
                <div className="testimonial-item">
                  <p> Excelente equipe, sempre inovando e entregando projetos de alta qualidade." - Cliente B Cotovia</p>
                </div>
                <div className="testimonial-item">
                  <p>Nosso sistema ficou muito mais eficiente graças à consultoria da Genius In Tech." - Cliente C  VLZM</p>
                </div>
              </div>
            </section>
            <section className="_secContato" id="contact">
              <h2>Contato</h2>
              <form>
                <article>
                  <label htmlFor="name">Nome:</label>
                  <input type="text" id="name" name="name" required />
                </article>
                <article>
                  <label htmlFor="email">Email:</label>
                  <input type="email" id="email" name="email" required />
                </article>
                <article>
                  <label htmlFor="message">Mensagem:</label>
                  <textarea id="message" name="message" required></textarea>
                </article>
                <article>
                  <button type="submit">Enviar</button>
                </article>
              </form>
            </section>
          </main>
        </div>
      </div>
      <ContactPage />
      <footer className="Footer">
        <div className="Footer-sections">
          <div className="Footer-contact">
            <h4>Entre em Contato</h4>
            <p>Email: contato@geniusintech.com.br</p>
            <p>Telefone: (11) 97341-8998</p>
            <p>Endereço: Praça Gen. Gentil Falcão, 51 - Brooklin - SP</p>
          </div>
          <div className="Footer-links">
            <h4>Links Úteis</h4>
            <ul>
              <li><a href="/sobre">Sobre Nós</a></li>
              <li><a href="/servicos">Serviços</a></li>
              <li><a href="/blog">Blog</a></li>
              <li><a href="/politica-de-privacidade">Política de Privacidade</a></li>
            </ul>
          </div>
          <div className="Footer-social">
            <h4>Siga-nos</h4>
            <a href="https://facebook.com/geniusintech" target="_blank" rel="noopener noreferrer">Facebook</a>
            <a href="https://linkedin.com/company/geniusintech" target="_blank" rel="noopener noreferrer">LinkedIn</a>
            <a href="https://instagram.com/geniusintech" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
          <div className="Footer-newsletter">
            <h4>Assine nossa Newsletter</h4>
            <article>
              <input type="email" placeholder="Seu email" />
              <button>Inscrever-se</button>
            </article>
          </div>
        </div>
        <div className="Footer-copyright">
          <p>&copy; 2024 Geniusintech. Todos os direitos reservados.</p>
        </div>
      </footer>
    </div >
  );
}
export default Home;
